import * as React from "react";
import { useHistory } from "react-router";

import Button from "../Button/Button";

import SuccesLogo from "../../Assets/Images/check.png";
import "./SuccesScreen.css";

interface SuccessScreenProps {
  title: string;
  heading: string;
  navigate?: string;
  button?: string;
  redirectToHomePage? :string
}

const SuccessScreen = ({ title, heading, navigate, button,redirectToHomePage }: SuccessScreenProps) => {
  const history = useHistory();

  React.useEffect(() => {
    if(redirectToHomePage){
      const timer = setTimeout(() => {
        history.replace("HomeScreen");
      }, 4000);
    }
  }, []);
  const handler = () => {
    history.replace(`/${navigate}`);
  };

  return (
    <div>
      <div className="container">
        <div className="header">
          <div className="logoContainer">
            <img src={SuccesLogo} alt="logo" />
          </div>
          <div className="successScreenContainer">
            <h2 className="succesTitle">{title} </h2>
            <h4 className="title">{heading}</h4>
          </div>
          {button ? null : (
            <Button
              label="Continue"
              className="buttonContinue"
              onClick={handler}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SuccessScreen;
