import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import StepperComponent from "../Screens/Stepper";
import PersonalInfoScreen from "../Screens/Onboarding/PersonalInfo/PersonalInfoScreen";
import IdentificationStepper from "../Screens/AllSteppers/IdentificationStepper/IdentificationStepper";
import AdressStepper from "../Screens/AllSteppers/AdressStepper/AdressStepper";
import FinalizeOnboarding from "../Screens/AllSteppers/FinalizeOnboarding/FinalizeOnboarding";
import "../App.css";
import UserDetailsProvider from "../Provider/contextProvider";
import PrivateRoute from "../Authentication/PrivateRoutes";
import HomeScreen from "../Screens/HomeScreen/HomeScreen";
const Navigation = () => {
  return (
    <UserDetailsProvider>
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <PrivateRoute
            exact
            path="/PersonalInfo"
            component={PersonalInfoScreen}
          />
          <PrivateRoute
            exact
            path="/Identification"
            component={IdentificationStepper}
          />
          <PrivateRoute
            exact
            path="/AddressVerification"
            component={AdressStepper}
          />
          <PrivateRoute
            exact
            path="/FinalizeOnboarding"
            component={FinalizeOnboarding}
          />
           <PrivateRoute
            exact
            path="/HomeScreen"
            component={HomeScreen}
          />
          <Route path="/" component={StepperComponent} />
        </Switch>
      </Router>
    </UserDetailsProvider>
  );
};

export default Navigation;
