import * as React from "react";
import Stepper from "bs-stepper";

import "bs-stepper/dist/css/bs-stepper.min.css";
import Info from "./Login/Info/Info";
import NumberVarification from "./Login/NumberVarification";
import OTP from "./Login/OTP";

const StepperComponent = () => {
  const stepperRef = React.useRef(null);

  React.useEffect(() => {
    stepperRef.current = new Stepper(document.querySelector("#stepper1"), {
      linear: false,
      animation: true,
    })
  }, [])

  const onSubmit = (e) => {
    e.preventDefault();
  }

  const handleToUpdate = () => {
    stepperRef?.current?.next();
  };

  return (
    <div style={{ margin: "auto" }}>
      <div id="stepper1" className="bs-stepper">
        <div className="bs-stepper-header">
          <div className="step" data-target="#test-l-1">
            <button className="step-trigger">
              {/* <h2 className="bs-stepper-label">Resident</h2> */}
            </button>
          </div>
          <div className="line"></div>
          <div className="step" data-target="#test-l-2">
            <button className="step-trigger">
              {/* <h2 className="bs-stepper-label">Number Verification</h2> */}
            </button>
          </div>
          <div className="line"></div>
          <div className="step" data-target="#test-l-3">
            <button className="step-trigger">
              {/* <h2 className="bs-stepper-label">OTP</h2> */}
            </button>
          </div>
        </div>
        <div className="bs-stepper-content">
          <form onSubmit={onSubmit}>
            <div id="test-l-1" className="content">
              <Info handleToUpdate={handleToUpdate} />

            </div>
            <div id="test-l-2" className="content">
              <NumberVarification handleToUpdate={handleToUpdate} />
            </div>
            <div id="test-l-3" className="content text-center">
              <OTP />
            </div>
          </form>
        </div>
      </div>
    </div>
  );

}

export default StepperComponent;
