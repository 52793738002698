import React from "react";

import Button from "../../../Components/Button/Button";
import PrivacyLogo from "../../../Assets/svg/PrivacyLogo";
import { DWOLLA_TOS, DWOLLA_PP, CARL_TOS_PP } from "../../../Constant";
import { Info } from "../../../Api/onBoarding";
import { GetUserStatus } from "../../../Api/onBoarding";
import { AppContext } from "../../../Context/Context";
import "./style.css";
import { unregister } from "../../../Api/interceptor";

const Termsandcondition = ({
  handleToUpdate,
}: {
  handleToUpdate: () => void;
}) => {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmailAddress,
    userId,
    setUserId,
    setToken,
    token,
  } = React.useContext(AppContext);
  const [loading, setLoading] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  const userData = async () => {
    const response = await Info.GetUserDetails(userId);
    const userDetails = response.message.data.personalinfodata;
    setFirstName(userDetails.firstName);
    setLastName(userDetails.lastName);
    setEmailAddress(userDetails.emailAddress);
  };

  React.useEffect(() => {
    setToken(localStorage.getItem("token"));
    userData();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
      };

      const response = await Info.GetUserDetails(userId!);
      const res = await Info.submitUserDataToCARL(data, userId);
      handleToUpdate();
    } catch (error: any) {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="header">
        <div className="headerContainer">
          <h2 className="title">Your application is ready</h2>
        </div>
        <div className="logoContainer">
          <PrivacyLogo />
        </div>
        <div className="termsContainer">
          {" "}
          <div className="inputContainer">
            <input
              name="options"
              type="radio"
              className="input"
              id="link"
              onChange={() => setChecked(true)}
            />
            <label htmlFor="link" className="labelText">
              I have read and I agree to{" "}
              <a
                className="termLink"
                href={DWOLLA_PP}
                target="_blank"
                rel="noreferrer"
              >
                CARL Terms of Service and Privacy Policy
              </a>
              , as well as our partner{" "}
              <a
                className="termLink"
                href={DWOLLA_TOS}
                target="_blank"
                rel="noreferrer"
              >
                Dwolla Terms of Service
              </a>{" "}
              and{" "}
              <a
                className="termLink"
                href={CARL_TOS_PP}
                target="_blank"
                rel="noreferrer"
              >
                Dwolla Privacy Policy
              </a>
              .
            </label>
          </div>
        </div>
        <Button
          label="Agree & Submit my application"
          className={checked ? "TermsButton" : "deactiveButton"}
          icon={
            loading ? (
              <i className="fas fa-circle-notch fa-spin mt-2"></i>
            ) : null
          }
          onClick={handleSubmit}
          disabled={!checked}
        />
      </div>
    </div>
  );
};
export default Termsandcondition;
