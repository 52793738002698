import React from "react";
import SuccesLogo from "../../Assets/Images/check.png";
import "./HomeScreen.css";
const HomeScreen = () => {
  return (
        <div className="container">

          <div className="header">
            <div className="logoContainer">
              <img src={SuccesLogo} alt="logo" />
            </div>  
            <div className="successScreenContainer">
              <h2 className="succesTitle">Success </h2>
              <h4 className="title">Your onboarding is on Process</h4>
              <h4 className="title">We are working hard to add features</h4>
            </div>
          </div>
        </div>
  );
};

export default HomeScreen;