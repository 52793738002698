import React from "react";
import InputMask from "react-input-mask";
import { AppContext } from "../../../Context/Context";

import Button from "../../../Components/Button/Button";
import { authentication } from "../../../Api/authentication";

import Logo from "../../../Assets/Images/logo.png";
import "./../../../Components/Input/PhoneNumberInput/style.css";
import "./style.css";

const NumberVarification = ({
  handleToUpdate,
}: {
  handleToUpdate: () => void;
}) => {
  const { phone_Number, set_phone_number, setUserId, isUSResident } =
    React.useContext(AppContext);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const phone_number = phone_Number.replace(/[^+0-9]/g, "");

  const Submit = async () => {
    setLoading(true);
    if (phone_Number.length < 13) {
      setError(true);
      setLoading(false);
    } else {
      try {
        const response = await authentication.signIn({
          phone_number: phone_number,
        });
        if (response.status) {
          set_phone_number(response.message.phone_number);
          setUserId(response.message.id);
          localStorage.setItem("userId" , JSON.stringify(response.message.id))
        }
        setLoading(false);
        handleToUpdate();
      } catch (error: any) {}
    }
  };

  return (
    <div className="container1">
      <div className="header">
        <div style={{ textAlign: "center" }}>
          <img src={Logo} alt="logo" />
          <h1 className="mainTitle">Welcome To CARL</h1>
        </div>
        <div>
          <h6 className="title">Enter your mobile number to continue</h6>
        </div>
        <div>
          <h6 className="titleNumber">Mobile number</h6>
        </div>
        <InputMask
          mask={isUSResident ? "+9 999 999 9999" : "+99 999 99 99999"}
          className="Input"
          maskChar={null}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            set_phone_number(e.target.value)
          }
          value={phone_Number}
        />
        {error ? (
          <h6 style={{ color: "#ff4d4d", textAlign: "center", marginTop: 10 }}>
            Error, Please enter a valid phone number
          </h6>
        ) : null}
        <Button
          label="Continue"
          className="buttonContinue"
          icon={
            loading ? (
              <i className="fas fa-circle-notch fa-spin mt-2"></i>
            ) : null
          }
          disabled={loading}
          onClick={Submit}
        />
        <div>
          <p className="footer">
            Trouble logging in? <span className="footerSpan">Chat with us</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NumberVarification;
