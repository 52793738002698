import * as React from 'react';
import "./style.css";

interface InputFieldProps {
  id?: string
  name: string;
  placeholder: string;
  type: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputField = ({ name, placeholder, type, onChange, value, id }: InputFieldProps) => {
  return (
    <div className="form-group">
      <input
        id={id}
        type={type}
        name={name}
        className="inputField"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        autoComplete="off"
      />
    </div>
  );
}

export default InputField;
