import React from "react";
import InputMask from "react-input-mask";
import Button from "../../../Components/Button/Button";
import InputField from "../../../Components/Input/InputField/InputField";
import { Info } from "../../../Api/onBoarding";
import { AppContext } from "../../../Context/Context";

import "./perfsonalInfoStyle.css";
import Logo from "../../../Assets/Images/logo.png";
import dayjs from "dayjs";

export default function PersonalInfo({
  handleToUpdate,
}: {
  handleToUpdate: () => void;
}) {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [dateOfBirth, setDateOfBirth] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | boolean>(false);
  const [SSN, setSSN] = React.useState("");
  const { userId, setUserId } = React.useContext(AppContext);
  const isInputEmpty =
    firstName && lastName && email && dateOfBirth && SSN !== "" ? true : false;

  const ssn = Number(SSN.replace(/[^+0-9]/g, ""));
  const dob = dayjs(dateOfBirth).format("MM-DD-YYYY");


  const newDate = dayjs()
  const eighteenYears = newDate.year() - 18
  const birthDate1 = newDate.format(dateOfBirth)
  const minDate1 = newDate.year(eighteenYears).format()
 
  React.useEffect(() => {
    const startDate = dayjs(new Date().setFullYear(new Date().getFullYear() - 21)).format()
    const date = dayjs(startDate).format("YYYY-MM-DD");
    setDateOfBirth(date);
  }, []);

  const handleSubmit = async () => {
    const userDetails: any = {
      firstName: firstName,
      lastName: lastName,
      email: email,
    };
    localStorage.setItem("UserDetails", JSON.stringify(userDetails));

    setLoading(true);
    const userData = {
      firstName,
      lastName,
      email,
      dob,
      ssn,
      userId,
    };
    if(birthDate1 > minDate1){
      setLoading(false);
      setError("You must be 18 years old")
    }else{
      try {
        const response = await Info.update(userData);
        if (response.status) {
          if(response.message.success === false){
            setError(response.message.message)
            setLoading(false);
          }else{
            handleToUpdate();

          }
        } else {
          setError(response.message);
          setLoading(false);
        }
      } catch (error: any) {
        console.log(error, "error");
      }
    }
    
  };
  return (
    <div className="container">
      <div className="header">
        <div className="mainTitleContainer">
          <img src={Logo} width="60px" alt="Logo" />{" "}
          <span className="mainTitle">CARL</span>
        </div>
        <div className="mainheadingContainer">
          <h4 className="title">Fill your information before continue</h4>
        </div>
        <div style={{ marginTop: 20 }}>
          <InputField
            type="text"
            placeholder="First Name"
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <InputField
            type="text"
            placeholder="Last name"
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <InputField
            type="email"
            placeholder="Email Address"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputField
            type="date"
            placeholder="Date od Birth"
            name="dob"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
          />
          <InputMask
            mask="999-99-9999"
            className="inputField"
            maskChar={null}
            onChange={(e) => setSSN(e.target.value)}
            placeholder="Social Security Number"
          />
        </div>

        <div>
          <h6 style={{ color: "red", marginTop: 20, textAlign: "center" }}>
            {" "}
            {error ? `* ${error}` : null}
          </h6>{" "}
        </div>

        <div className="button-cont-text">
          <Button
            label="Continue"
            className={isInputEmpty ? "buttonContinue" : "deactiveButton"}
            icon={
              loading ? (
                <i className="fas fa-circle-notch fa-spin mt-2"></i>
              ) : null
            }
            disabled={!isInputEmpty || loading}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
