import React from "react";
import { KYC } from "../../../Api/onBoarding";
import Button from "../../../Components/Button/Button";
import FileUploader from "../../../Components/Input/FileUploadInput/FileUploadInput";
import { AppContext } from "../../../Context/Context";

type props = {
  title?: string;
  id?: number;
};

interface UploadDocumentsProps {
  handleToUpdate: () => void;
  documentTitle: props;
}

function UploadDocuments({
  handleToUpdate,
  documentTitle,
}: UploadDocumentsProps) {
  const [frontImage, setFrontImage] = React.useState<File | null>(null);
  const [backImage, setBackImage] = React.useState<File | null>(null);
  const [loading, setLoading] = React.useState(false);
  let { userId } = React.useContext(AppContext);
  const { title: documentName, id: documentType } = documentTitle;
  const isInputEmpty =
    documentName === "Passport"
      ? frontImage === null
      : frontImage === null || backImage === null;

  const submitForm = async (e: Event) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      userId,
      documentType,
      frontImage,
      backImage,
    };
    
    try {
      const res = await KYC.submit(data);
      if (res.data.success) {
        handleToUpdate();
      } else {
        setLoading(true);
      }
    } catch (error) {
      setLoading(true);    }
  };
  return (
    <div className="container">
      <div className="header">
        <form>
          <div className="topContainer">
            <h1 className="title">{`Upload your ${documentName}`}</h1>
            {documentName === "Passport" ? (
              <div className="titleMain">
                <FileUploader onFileSelect={(file) => setFrontImage(file)} />
             
                <h6 className="imageName" >{frontImage?.name}</h6>
              </div>
            ) : (
              <div className="titleMain">
                <FileUploader
                  heading="Front of your Card"
                  onFileSelect={(file) => setFrontImage(file)}
                />
                    <h6 className="imageName">{frontImage?.name}</h6>
                <FileUploader
                  heading="Back of your Card"
                  onFileSelect={(file) => setBackImage(file)}
                />
                    <h6 className="imageName">{backImage?.name}</h6>
              </div>
            )}
          </div>

          <Button
            label="Continue"
            className={isInputEmpty ? "disableButton" : "buttonContinue"}
            disabled={isInputEmpty}
            icon={
              loading ? (
                <i className="fas fa-circle-notch fa-spin mt-2"></i>
              ) : null
            }
            onClick={submitForm}
          />
        </form>
      </div>
    </div>
  );
}

export default UploadDocuments;
