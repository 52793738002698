interface itemProps { id: number | string; name: string }

interface SelectDropDownProps {
  captionKey: string;
  className: string;
  items: itemProps[];
  handler: (value: string) => void;

}

const SelectDropDown = ({
  captionKey,
  handler,
  className,
  items,
}: SelectDropDownProps) => {
  return (
    <select
      className={className}
      onChange={(e) => handler(e.target.value)}
    >
      <option value="" disabled selected>
          Select One
      </option>
      {items.map((item: itemProps) => (
        <option key={item['id']} value={item['id']}>
          {(item as any)[captionKey]}
        </option>
      ))}
    </select>
  );
}

export default SelectDropDown;
