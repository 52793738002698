import HttpRequest from "./httpRequest";
import { env } from "../Constant/env";
import Axios from "axios";

// import  mime from 'mime'

export enum Investor {
  Entity = 1,
  Individual = 2,
  None = 3,
}

interface AddressDetails {
  company: string;
  email: string;
  firstName: string;
  lastName: string;
  userId: number;
}

interface IndividualForm {
  email: string;
  firstName: string;
  citizenship: string;
  userId: number;
}

const responseSerizer = (res: any) =>
  Object.keys(res).reduce((acc, key) => {
    const { title, placeholder, type, required, value } = res[key];
    return {
      ...acc,
      [key]: { title, placeholder, type, required, options: value },
    };
  }, {});

export const reverseLookup = (
  name: string,
  dict: { [key: string]: string }
): string =>
  (Object.entries(dict).filter(([key, value]) => value === name)[0] || [
    null,
    null,
  ])[0];

export const GetUserStatus = {
  fetchMainData: async (userId: number) => {
    const url = `${env.ENDPOINT}/v3/users/${userId}/info/`;
    const response = await HttpRequest.get(url);
    return response;
  },
};

export const Info = {
  submitUserDataToCARL: async (data: any, userId: number) => {
    console.log(data, "data last");
    const { email, firstName, lastName } = data;
    const url = `${env.ENDPOINT}/carl/submit-application`;
    const body = JSON.stringify({
      firstname: firstName,
      lastname: lastName,
      emailaddress: email,
      id: userId,
    });
    return await HttpRequest.post(url, body);
  },

  GetUserDetails: async (userId: number) => {
    const url = `${env.ONBOARING_ENDPOINT}/getUserDetails`;
    const body = JSON.stringify({ userId });
    return await HttpRequest.post(url, body);
  },
  submitEntityForm: async ({
    company,
    email,
    firstName,
    lastName,
    userId,
  }: AddressDetails) => {
    const url = `${env.ONBOARING_ENDPOINT}/submitEntityForm`;
    const body = JSON.stringify({
      company,
      email,
      firstName,
      lastName,
      userId,
    });

    return await HttpRequest.post(url, body);
  },
  submitIndividualForm: async (userId: number, investorOption: number) => {
    const url = `${env.ONBOARING_ENDPOINT}/submitIndividualForm`;
    const body = JSON.stringify({ userId, investorOption });
    return await HttpRequest.post(url, body);
  },
  submitIndividualFormNoNUS: async ({
    email,
    firstName,
    citizenship,
    userId,
  }: IndividualForm) => {
    const url = `${env.ONBOARING_ENDPOINT}/submitIndividualFormNonUS`;
    const body = JSON.stringify({ email, firstName, citizenship, userId });

    return await HttpRequest.post(url, body);
  },
  submitPetitionForm: async (data: any) => {
    const url = `${env.ONBOARING_ENDPOINT}/submitPetitionForm`;
    return await HttpRequest.post(url, data);
  },

  setInvestorType: async (
    investorType: number,
    mobileNumber: string,
    userId: number
  ) => {
    const url = `${env.ONBOARING_ENDPOINT}/setinvestortype`;
    const body = JSON.stringify({ investorType, mobileNumber, userId });
    return await HttpRequest.post(url, body);
  },
  get: async (type: Investor, userId: number) => {
    const url = `${env.ONBOARING_ENDPOINT}/getallpersonalinformation/${type}/${userId}`;
    const res = await fetch(url);
    if (!res.ok) throw new Error(await res.text());

    const data = await res.json();
    return responseSerizer(data);
  },
  update: async (data: any) => {
    try {
      const url = `${env.ONBOARING_ENDPOINT}/submitPersonalInfo`;
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!res.ok) throw new Error(await res.text());
      const message = await res.json();
      // in case if backend send ok true then we need to check success
      if (!message?.success) {
        if (typeof message?.message === "object") {
          const msg = Object.values(message?.message)[0] as string;
          throw new Error(msg);
        }
      }
      return {
        message,
        status: true,
      };
    } catch (error: any) {
      return { message: error.message, status: false };
    }
  },
};
export const KYC = {
  get: async () => {
    const url = `${env.ONBOARING_ENDPOINT}/getKycOptions`;
    const res = await fetch(url);
    if (!res.ok) throw new Error(await res.text());
    return res.json();
  },

  submit: async (data: any) => {
    const { userId, documentType, frontImage, backImage } = data;
    const url = `${env.ONBOARING_ENDPOINT}/submitKycInfo`;
    const body = new FormData();
    body.append("userId", userId);
    body.append("documentType", documentType);
    body.append("frontImage", frontImage);
    if (backImage !== null) {
      body.append("backImage", backImage);
    }

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const res = await Axios.post(url, body, config);
    return res;
    
    // const res = await fetch(url, {
    //   method: "POST",
    //   body ,
    //   headers:{ "Content-Type" : "multipart/form-data"}
    // });
    // return res.json();
  },
};

export const Accreditation = {
  get: async () => {
    const url = `${env.ONBOARING_ENDPOINT}/getAccreditonOptions`;
    const res = await fetch(url);
    if (!res.ok) throw new Error(await res.text());
    return res.json();
  },
  submit: async (data: any) => {
    const { userId, optionType, image } = data;
    const body = new FormData();
    body.append("userId", `${userId}`);
    body.append("optionType", `${optionType}`);
    body.append("image", { name: "image", ...image });

    const url = `${env.ONBOARING_ENDPOINT}/submitAccreditionInfo`;

    const res = await fetch(url, { method: "POST", body });
    if (!res.ok) throw new Error(await res.text());

    return res.json();
  },
};

export const Address = {
  get: async () => {
    const url = `${env.ONBOARING_ENDPOINT}/getAddressInfo`;
    const res = await fetch(url);
    if (!res.ok) throw new Error(await res.text());

    return res.json();
  },
  submit: async (data: any) => {
    const { userId, address1, address2, city, state, zipCode } = data;
    console.log(data);
    const url = `${env.ONBOARING_ENDPOINT}/submitAddressInfo`;
    const body = JSON.stringify({
      userId,
      address1,
      address2: address2 || "undifined",
      city,
      state,
      zipCode,
    });
    return await HttpRequest.post(url, body);
  },
};

export const Resubmit = {
  checkRequiredDocs: async (userId: string) => {
    const url = `${env.ONBOARING_ENDPOINT}/resubmitUserDocuments/${userId}`;
    const response = await HttpRequest.get(url);
    return response;
  },
  updateUserDocuments: async (data: any) => {
    const url = `${env.ONBOARING_ENDPOINT}/updateUserDocuments`;
    const body = new FormData();
    Object.keys(data).forEach((key, index) => {
      body.append(key, data[key]);
    });
    console.log({
      body,
    });
    const response = await HttpRequest.post(url, body);
    return response;
  },
};
