import * as React from "react";
import { useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";
import { AppContext } from "../../../Context/Context";
import { Info } from "../../../Api/onBoarding";
// Custom components
import Button from "../../../Components/Button/Button";
import { authentication } from "../../../Api/authentication";
import { ApplicationStatus } from "../../../Constant/onboardingData";
// CSS & images
import "./OTP.css";
import Icon from "../../../Assets/Images/otpIcon1.png";

export default function OTP() {
  const { phone_Number, isUSResident, userId, setToken } =
    React.useContext(AppContext);
  const [code, setCode] = React.useState("");
  const [error, setError] = React.useState(false);
  const [counter, setCounter] = React.useState(60);
  const [loading, setLoading] = React.useState(false);
  const [application_status, set_application_status] = React.useState("");
  const history = useHistory();
  const push_token = "";
  React.useEffect(() => {
    counterHandler();
  }, [counter]);

  const counterHandler = () => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  };

  const ResendOtp = async () => {
    const response = await authentication.signIn({
      phone_number: phone_Number,
    });
    setError(false);
    setCode("");
    alert("Succesfully send OTP");
    setCounter(60);
  };

  const handleConfirmation = async () => {
    setLoading(true);
    try {
      const response = await authentication.confirmation(
        userId,
        code,
        push_token
      );
   
      if (response.status) {
        setToken(response.message.token);
        set_application_status(response.message.application_status);
        localStorage.setItem( "token",response.message.token );
        localStorage.setItem( "isUserLogin",JSON.stringify(true) );


        if (response.message.is_new_user) {
          await authentication.sendNewUserPhoneNumber({
            userId: userId,
            phoneNumber: phone_Number,
          });
        }
        setError(false);
        history.replace("PersonalInfo")
      } else {
        setError(true);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  return (
    <div className="container1">
      <div className="header">
        <div className="mainHeader">
          <h4 className="mainTitle">Enter OTP</h4>
          <img src={Icon} alt="logo" />
        </div>
        <div style={{ marginTop: 20 }}>
          <h5 className="titleCode">Enter the code sent to {phone_Number}</h5>
        </div>
        <div className="otpContainer">
          <OtpInput
            value={code}
            onChange={setCode}
            inputStyle="inputFields"
            isInputNum
            separator={<span>-</span>}
          />
        </div>
        {error ? (
          <h6 className="errorText">The code was note correct , try again</h6>
        ) : null}

        <Button
          label="Verify"
          className={code.length > 3 ? "activeButton" : "deactiveButton"}
          disabled={code.length <= 3 || loading}
          onClick={handleConfirmation}
          icon={
            loading ? <i className="fas fa-circle-notch fa-spin"></i> : null
          }
        />
        <div>
          <p className="footer">
            <button
              onClick={ResendOtp}
              disabled={counter !== 0 ? true : false}
              className="otpQuestionText"
            >
              Didn’t recieve a code?
            </button>
            you can resend in
            <span className="footerSpan">
              {" "}
              00-{counter < 10 ? `0${counter}` : counter}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
