import * as React from "react";
import MainStepper from "../../../Components/Stepper/MainStepper";
import InvesterCategory from "./investorCategory";
import PersonalInfo from "./PersonalInfo";
import MessageScreen from "../../../Components/SuccessScreen/SuccessScreen";
import { AppContext } from "../../../Context/Context";
import { INVESTOR_TYPE } from "../../../Constant/onboardingData";

const PersonalInfoScreen = () => {
  const [currentStep, setcurrentStep] = React.useState(0);
  const { isUSResident,firstName,email,investorType } = React.useContext(AppContext);
  const userType = investorType !== INVESTOR_TYPE.Entity
  const arraySteps = [
    { title: "Inverstor Type" },
    isUSResident ? { title: "Personal Info" } : { title: "" },
    { title: "Success" },
  ];

  const handleToUpdate = () => {
    isUSResident && userType
      ? setcurrentStep(currentStep + 1)
      : setcurrentStep(currentStep + 2);
  };

  const renderComponent = () => {
    switch (currentStep) {
      case 0:
        return <InvesterCategory handleToUpdate={handleToUpdate} />;
      case 1:
        return <PersonalInfo handleToUpdate={handleToUpdate} />;
      case 2:
        return  isUSResident && userType ? (
          <MessageScreen
            title="Success"
            heading="We have recieved your Personal Information"
            navigate="AddressVerification"
          />
        ) : (
          <MessageScreen
            title="Success"
            heading={`Thank you, ${firstName}. We will email you at ${email} to schedule a time to speak.`}
            button="Visible"
          />
        );
      default:
        return null;
    }
  };

  return (
    <MainStepper currentStep={currentStep} stepsArray={arraySteps}>
      {renderComponent()}
    </MainStepper>
  );
};

export default PersonalInfoScreen;
