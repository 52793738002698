import Button from "../../../Components/Button/Button";
import Logo from "../../../Assets/Images/card.png";
import "./style.css";

const Identification = ({ handleToUpdate }: { handleToUpdate: () => void }) => {
  return (
    <div className="container">
      <div className="header">
        <div className="logoContainer">
          <img src={Logo} alt="logo" />
        </div>

        <div>
          <h4 className="title">
            Next, we need to verify your identity by taking a picture of your ID
          </h4>
        </div>

        <Button
          label="Continue"
          className="buttonContinue"
          onClick={handleToUpdate}
        />
      </div>
    </div>
  );
};

export default Identification;
