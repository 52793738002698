import React, { useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { ApplicationStatus } from "../Constant/onboardingData";

import useAuthenticatedRoute from "./useAuthenticatedRoute";
import { Info } from "../Api/onBoarding";
import LoadingScreen from "../Screens/LoadingScreen/LoadingScreen";
import { GetUserStatus } from "../Api/onBoarding";
import { AppContext } from "../Context/Context";

const PrivateRoute = ({ path, component }: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const { userId } = React.useContext<any>(AppContext);
  const history = useHistory();

  React.useEffect(() => {
    if(userId){
      checkFlow().finally(() => setIsLoading(false));
    }
  }, [userId]);

  const checkFlow = async () => {
    const data = await GetUserStatus.fetchMainData(userId);
    try {
      const applicationStatus = data.message.application_status;
      const { message = {} } = await Info.GetUserDetails(userId);
       console.log(message,"message")
      if (applicationStatus === ApplicationStatus.PENDING) {
        history.replace("HomeScreen");
      } else {
        if (message.success) {
          const {
            data: {
              personalinfodata = {},
              kycinfodata = {},
              addressinfodata = {},
            },
          } = message;
          if (Object.keys(kycinfodata).length) {
            return history.push("FinalizeOnboarding");
          } else if (Object.keys(addressinfodata).length) {
            return history.push("Identification");
          }  else if (Object.keys(personalinfodata).length) {
            return history.push("AddressVerification");
          } else {
            return history.push("PersonalInfo");
          }
        }
      }
    } catch (error) {
      return history.push("PersonalInfo")
    }
  };

  const Component = component;
  const auth = useAuthenticatedRoute();

  return (
    <Route
      path={path}
      render={() => {
        if (isLoading) {
          return <LoadingScreen />;
        } else if (auth !== "true") {
          return <Redirect to="/" />;
        } else {
          return <Component />;
        }
      }}
    />
  );
};

export default PrivateRoute;
